<template>
  <div v-if="location">
    <modal-edit-logo
      v-if="showEditLocationLogo"
      :showModal="showEditLocationLogo"
      :onClickCancel="hideModal"
      @loadLogo="
        (val) => {
          logo = val
        }
      "
    />
    <transition name="fade" mode="out-in">
      <ui-loader v-if="isLoading" />
      <div v-else>
        <ui-section-header
          :showEdit="true"
          :editFunction="setShowEditLocationLogo"
        >
          <template v-slot:title>Logo</template>
        </ui-section-header>

        <message v-if="!logo" :message="'No logo found.'" />

        <transition-group name="fade" mode="out-in">
          <img
            v-if="logo"
            key="logo"
            :src="$options.filters.getLogo(logo.Image, location.Id)"
            class="logo"
          />
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locationProvider from '@/providers/location'

const Message = () => import('@/components/UI/Message')
const ModalEditLocationLogo = () =>
  import('@/components/Locations/ModalEditLocationLogo')

export default {
  components: {
    message: Message,
    'modal-edit-logo': ModalEditLocationLogo,
  },

  props: {},

  data() {
    return {
      isLoading: true,
      locationId: Number(this.$route.params.locationId),
      logo: null,
      showEditLocationLogo: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  watch: {},

  beforeCreate() {},

  created() {
    this.getLogo()
  },

  methods: {
    /**
     * Get location logo
     */
    getLogo() {
      let self = this
      locationProvider.methods
        .getLocationLogo(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.logo = response.data
          }
        })
        .catch((e) => {})
        .finally(() => {
          self.isLoading = false
        })
    },

    setShowEditLocationLogo() {
      this.showEditLocationLogo = true
    },

    hideModal() {
      this.showEditLocationLogo = false
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  max-height: 160px;
}
</style>
